<template>
  <div class="row mt-3 d-flex pl-2 w-100">
    <div class="col-6">
      <div class="card bg-light h-100">
        <div class="card-header">
          <div class="card-title d-flex align-items-center mb-0">
            <div class="avatar avatar-2xl mr-3">
              <img src="@/assets/img/illustrations/pdf-file.svg" alt="">
            </div>
            Загрузить и распечатать
          </div>
        </div>
        <div class="card-body">      
          <p class="card-text text-800" ref="target"><strong>Зачем загружать бизнес-план в формате PDF?</strong></p>
          <ul>
            <li class="fs--1">Возможность распечатать с любого принтера</li>
            <li class="fs--1">Легко отправлять по электронной почте или сделать резервную копию</li>
            <li class="fs--1">Невозможность для других вносить изменения в документ</li>
          </ul>
        </div>
        <div class="card-footer">
          <div class="btn btn-outline-success btn-block"
            @click="createPDF()">
            Загрузить в формате PDF
            <i class="fas fa-arrow-alt-circle-down ml-2"></i>
          </div>
        </div>
      </div>
    </div>
    <div class="col-6">
      <div v-if="false"
        class="card bg-light h-100">
        <div class="card-header">
          <div class="card-title d-flex align-items-center mb-0">
            <div class="avatar avatar-2xl mr-3">
              <img src="@/assets/img/illustrations/ms-word.svg" alt="">
            </div>
            Экспортировать в Word
          </div>
        </div>
        <div class="card-body">      
          <p class="card-text text-800"><strong>Зачем загружать бизнес-план в формате Word?</strong></p>
          <ul>
            <li class="fs--1">Форматирование документа по своему усмотрению</li>
            <li class="fs--1">Дать возможность другим вносить правки в документ</li>
          </ul>
          <p class="card-text text-800">
            <small>
              <i class="fas fa-exclamation mr-1 text-warning"></i>
              Внесенные изменеия не отобразяться в системе <span class="text-white font-proxima">ТВОЙ<small>план</small></span>
            </small>
          </p>
        </div>
        <div class="card-footer">
          <div class="btn btn-outline-success btn-block"
          @click="createDocx()">
            Загрузить в формате Word
            <i class="fas fa-arrow-alt-circle-down ml-2"></i>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { parse } from 'node-html-parser';

pdfMake.vfs = pdfFonts.pdfMake.vfs;



export default {
  methods: {
    async createPDF () {

      

      // Устанавливаем шрифты для отображения в PDF файле
      pdfMake.fonts = {
        ProximaNova: {
          normal: 'ProximaNova-Regular.ttf',
          bold: 'ProximaNova-Bold.ttf',
          italics: 'ProximaNova-RegularIt.ttf',
          bolditalics: 'ProximaNova-BoldIt.ttf',
        },
        Roboto: {
          normal: 'Roboto-Regular.ttf',
          bold: 'Roboto-Medium.ttf',
          italics: 'Roboto-Italic.ttf',
          bolditalics: 'Roboto-MediumItalic.ttf'
        }
      }

      const content = await this.prepareData()

        // Добавляем опции и стили в PDF 
        var docDefinition = {
          footer: function(currentPage, pageCount) { 
            return currentPage > 1 ? [ // не печатаем на лицеов странице
              {
                font: 'ProximaNova',
                fontSize: 9,
                text: currentPage.toString() + ' из ' + pageCount,
                alignment: 'right',
                margin: [0, 5, 50, 0] 
              }
            ] : '';
          },
          header: function(currentPage, pageCount, pageSize) {
            // you can apply any logic and return any valid pdfmake element

            return currentPage > 1 ? [ // не печатаем на лицевой страницу
              { 
                text: [{
                  text: 'ТВОЙ',
                  bold: true,
                  fontSize: 14,
                  color: '#1e5e98'
                }, {
                  text: ' бизнес-план',
                  bold: true,
                  fontSize: 8,
                  color: '#0f0c29'
                }], 
                alignment: 'right',
                margin: [0, 14, 50, 0] 
              }, { 
                canvas: [ { type: 'rect', x: 170, y: 32, w: pageSize.width - 170, h: 40 } ] 
              }
            ] : '';
          },
          content: await content,
          defaultStyle: {
            font: 'ProximaNova',
            fontSize: 11,
          },
          pageBreakBefore: function(currentNode, followingNodesOnPage) {
            return currentNode.headlineLevel === 1 && followingNodesOnPage.length === 0;
          },
          styles: {
            chapter: {
              fontSize: 23,
              bold: true,
              margin: [0, 0, 0, 7]
            },
            chapterOutline: {
              fontSize: 14,
              bold: true,
              margin: [20, 10, 0, 0]
            },
            section: {
              fontSize: 18,
              bold: true,
              margin: [0, 0, 0, 6]
            },
            sectionOutline: {
              fontSize: 12,
              bold: true,
              margin: [20, 5, 0, 4]
            },
            topicTitle: {
              fontSize: 12,
              bold: true,
              margin: [0, 0, 0, 4]
            },
            topicTitleOutline: {
              fontSize: 10,
              margin: [35, 0, 0, 2]
            },
            topic: {
              fontSize: 11,
              margin: [0, 0, 0, 7]
            },
            blockquote: {
              margin: [0, 10, 0, 5]
            },
            p: {
              fontSize: 11,
              margin: [0, 3]
            },
            list: {
              fontSize: 11,
              margin: [20, 0, 0, 0]
            },
            tableName: {
              fontSize: 10,
              alignment: 'right',
              bold: true,
              italics: true
            },
            tableHeader: {
              bold: true, 
              alignment: 'center',  
              margin: [0, 3, 0, 0]
            },
            categoryItemTitle: {
              
            },
            categoryItemValue: {
              fontSize: 10,
              alignment: 'right',
            },
            categoryTotalTitle: {
              fontSize: 10,
              bold: true,
            },
          }
        };

        // window.console.log(await content, moment().format('SSSSSSSSS'), 'контент ушел на печать')

        // window.console.log(await this.$store.dispatch('financialHighlightsByYearSvg', {width: 500, height: 300}))

        pdfMake.createPdf(docDefinition).open();
  
    },

    async prepareData() {
      var coverPage = []
      var tableOfContent = [] // создается в конце, т.к пишется по ходу заполнения информации
      var content = []
      var chapterIndex = 0;
      var tableCount = 0
      var chartCount = 0


      // Подготавливаем и добавляем COVER PAGE
      coverPage.push({
        text: 'БИЗНЕС-ПЛАН',
        fontSize: 40,
        bold: true,
        alignment: 'center',
        margin:[0, 250, 0, 0]
      });
      // добавляем sub-title 
      coverPage.push({
        text: this.$store.getters.getActiveBPlan.title,
        fontSize: 20,
        alignment: 'center',
        margin:[0, 15, 0, 0]
      });
      // Добавляем разрыв страницы
      coverPage.push({
        text: '',
        pageBreak: 'after'
      });
      
      // задаем первичное занчение Оглавлению
      tableOfContent.push({
        text: 'Оглавление',
        fontSize: 20,
        bold: true, 
        margin: [20, 40, 0, 10]
      });
      

      for (const chapter of this.$store.getters.currentOutline) {
        chapterIndex++
        
        //  Вносим запись о главе в огравление
        tableOfContent.push({
          text: chapter.title,
          style: 'chapterOutline'
        });

        // Печатаем название главы
        content.push({
          text: chapter.title,
          style: 'chapter'
        });
        
        var sectionIndex = 0; // обновляем счетчик перед каждым разделом
        // Печатаем содержание главы
        for (const section of chapter.sections) {
          sectionIndex++
          //  Вносим запись о разделе в огравление
          tableOfContent.push({
            text: `${chapterIndex}.${sectionIndex} ${section.title}`,
            style: 'sectionOutline'
          });

          content.push({
            text: section.title,
            style: 'section'
          });

          var topicIndex = 0; // обнуляем счетчик перед началом
          // Формируем отображение по каждому топику
          for (const topic of section.topics) {
            topicIndex++;
            //  Вносим запись о теме в огравление
            tableOfContent.push({
              text: `${chapterIndex}.${sectionIndex}.${topicIndex} ${topic.title}`,
              style: 'topicTitleOutline'
            });

            // Отображаем заголовок если только пользователь это установил
            if (topic.showTitle && topic.type != 'table') {
              content.push({
                text: topic.title,
                style: 'topicTitle'
              });
            }            

            switch (topic.type) {
              case 'table':
                tableCount++
                content.push({
                  text: `Таблица #${tableCount}: ` + topic.title.toLowerCase(),
                  style: 'tableName'
                });
                // Загружаем данные по ссылке из базы
                var table = await this.$store.dispatch(`${topic.tableDataRef}PdfData`) 
                

                content.push({
                  layout: {
                    vLineWidth: function () {
                      return 0.5;
                    },
                    hLineWidth: function (i) {
                      switch (i) {
                        case 1:
                          return 1.5
                        default:
                          return 0.5
                      }
                    },
                    hLineColor: function (i, node) {
                      switch (i) {
                        case 0:
                          return '#4d5969'
                        case 1:
                          return '#4d5969'
                        case node.table.body.length:
                          return '#4d5969'
                        default:
                          return '#edf2f9'
                      }
                    },
                    vLineColor: function (i, node) {
                      return (i === 0 || i === node.table.widths.length) ? '#4d5969' : '#c3c4c5';
                    },
                  }, // optional
                  table: {
                    heights: function (row) {
                      return (row === 0) ? 20 : 12;
                    },
                    widths: table.widths, 
                    body: table.tableData,
                  },
                });
                break;
              
              case 'chart':
                // Увеличиваем рефернтный индекс таблицы
                chartCount++
                var res = await this.$store.dispatch(`${topic.chartId}Svg`, {width: 520, height: 200})
                content.push({
                  text: `Chart #${chartCount}: ` + topic.title.toLowerCase(),
                  style: 'tableName',
                  margin: [0, 10, 10, 0]
                });
                content.push({
                  svg: res.data,
                  alignment: 'center'
                })
                break;
            
              default:
                // Парсим текст топика для выделения элементов форматирования
                await parse(topic.body).childNodes.map(item => {
                  // Обрабатываем цитаты
                  if (item.tagName == 'blockquote') {
                    content.push({
                      layout: {
                        fillColor: '#eff4f8',
                        vLineWidth: function (i) {
                          return (i === 0) ? 2 : 0;
                        },
                        hLineWidth: function () {
                          return 0;
                        },
                        vLineColor: function (i) {
                          return (i === 0) ? '#4d5969' : 'none';
                        },
                      }, // optional
                      style: 'blockquote',
                      table: {
                        widths:[500],
                        body: [
                          [{text: item.text, italics: true, margin: [10, 2]}],
                        ]
                      },
                    });
                  }

                  // Обрабатываем параграфы и придаем форматирование тексту
                  if (item.tagName == 'p') {
                    var pText = []
                    item.childNodes.map(item => {
                      var text = item.text.replace(/&nbsp;/gi, ' ')
                      pText.push({
                        text: text,
                        bold: item.tagName == 'strong' || (item.lastChild ? item.lastChild.tagName == 'strong' : false) ? true : false,
                        italics: item.tagName == 'i' ? true : false,
                      });
                    });
                    content.push({
                      text: pText,
                      style: 'p'
                    });
                  }

                  // Обрабатываем списки
                  if (item.tagName == 'ol') {
                    var list = []
                    item.childNodes.map(li => {
                      if (li.childNodes.length == 1) {
                        list.push(li.text)
                      } else {
                        list.push(li.text)
                        for(var i = 1; i < li.childNodes.length; i++) {
                          var list2 = []
                          li.childNodes[i].childNodes.map(li2 => {
                            list2.push(li2.text)
                          })
                          list.push({
                            [li.childNodes[i].tagName]: list2,
                            style: 'list'
                          })
                        }
                      }
                      
                    })
                    content.push({
                      [item.tagName]: list,
                      style: 'list'
                    })
                  }
                });
                break;
            }
            content.push({
              text: '',
              margin: [0, 5]
            })
          }      
        }
        // Если остались еще главы, то добавляем новый разрыв строки
        if (chapterIndex < this.$store.getters.currentOutline.length) {
          content.push({
            text: '',
            pageBreak: 'before'
          })
        } else { // в противном случае добавляем Оглавление
          // разрыв страницы после оглавления
          tableOfContent.push({
            text: '',
            pageBreak: 'before'
          });
          
          // Объедияем контент, оглавление и титульную страницу
          content = tableOfContent.concat(content);
          content = coverPage.concat(content);

        }
        
      }

      return content
    } 
  }
}
</script>

<style>

</style>